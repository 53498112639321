<template>
    <div id="detailp" class="project-detail">
        <img src="../assets/images/Asset 7695.png" width="100%" alt="">
    <div class="container-fluid mt-5 my-5">
      <div class="container">
        <div class="row text-start">
          <div class="col-6 ">
            <div class="row">
              <div class="text-first col-12">
                <h4>BLAUPUNKT</h4>
                <hr style="border-top: 3px solid #058CFF; width: 50px; opacity: 100;">
              </div>
            </div>
            <div class="row mt-3 ">
              <div class="col-12">
                <h5 class="title-list">Client</h5>
                <h5 class="list-content">Blaupunkt</h5>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <h5 class="title-list">Create</h5>
                <h5 class="list-content">2017</h5>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <h5 class="title-list">Role</h5>
                <h5 class="list-content">3D Animasi</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 mt-2 d-flex justify-content-center">
                <div class="row">
                    <div class="col-12">
                    <router-link :to="{ name: 'project'}" >
                        <img src="../assets/images/Asset 184.png" width="65%" alt="">
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
      </div>  
    </div>



    <div class="container-fluid">
      <div class="container">
                <div class="row mt-5">
                    <div class="col-12 col-md-6 col-lg-6 ">
                        <div class="card m-0">
                            <router-link :to="{ name: 'detailproject'}" >
                                <img src="../assets/images/home/Asset 9300.png" class="img-fluid m-0" width="100%" alt="...">
                            </router-link>
                            <div class="card-body my-2 ms-2 ms-lg-4 text-start">
                                <h5 class="card-title home-ct">Blaupunkt</h5>
                                <p class="card-text">3D Animation</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 mt-5 mt-md-0 mt-lg-0">
                        <div class="card m-0">
                            <router-link :to="{ name: 'detailproject'}" >
                                <img src="../assets/images/home/Asset 11300.png" class="img-fluid m-0" width="100%" alt="...">
                            </router-link>
                            <div class="card-body my-2 ms-2 ms-lg-4 text-start">
                                <h5 class="card-title home-ct">Fisherman’s Friend TVC</h5>
                                <p class="card-text">Visual Effect</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 col-md-6 col-lg-6 ">
                        <div class="card m-0">
                            <router-link :to="{ name: 'detailproject'}" >
                                <img src="../assets/images/home/Asset 10300.png" class="img-fluid m-0" width="100%" alt="...">
                            </router-link>
                            <div class="card-body my-2 ms-2 ms-lg-4 text-start">
                                <h5 class="card-title home-ct">Riverview Residence</h5>
                                <p class="card-text">3D Archithecture</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 mt-5 mt-md-0 mt-lg-0">
                        <div class="card m-0">
                            <router-link :to="{ name: 'detailproject'}" >
                                <img src="../assets/images/home/Asset 12300.png" class="img-fluid m-0" width="100%" alt="...">
                            </router-link>
                            <div class="card-body my-2 ms-2 ms-lg-4 text-start">
                                <h5 class="card-title home-ct">Kompas</h5>
                                <p class="card-text">Motion Graphic</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col-12 col-md-6 col-lg-6 ">
                        <div class="card my-0 py-0">
                            <router-link :to="{ name: 'detailproject'}" >
                                <img src="../assets/images/home/Asset 8300.png" class="img-fluid m-0" width="100%" alt="...">
                            </router-link>
                            <div class="card-body my-2 ms-2 ms-lg-4 text-start">
                                <h5 class="card-title home-ct">Hanyalah Kucing - Bitsy (Official Music Video)</h5>
                                <p class="card-text">Visual Effect</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 mt-5 mt-md-0 mt-lg-0">
                        <div class="card m-0">
                            <router-link :to="{ name: 'detailproject'}" >
                                <img src="../assets/images/home/Asset 13300.png" class="img-fluid m-0" width="100%" alt="...">
                            </router-link>
                            <div class="card-body my-2 ms-2 ms-lg-4 text-start">
                                <h5 class="card-title home-ct">NHK JP TVC </h5>
                                <p class="card-text">3D Animation</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <!-- <div class="container">
        <h2 class="other">Other Project</h2>
          <div class="row">
            <div class="col-12">
                <div class="card-group" >
                  <div class="card">
                    <img src="../assets/images/pertama/Asset 6300.png" class="card-img-top" alt="...">
                    <div class="card-body" style="background-color: #999999;">
                      <h4 class="card-text title-other">Blaupunkt</h4>
                      <p class="content-other"> Animation</p>
                    </div>
                  </div>
                  <div class="card">
                    <img src="../assets/images/pertama/Asset 3300.png" class="card-img-top" alt="...">
                    <div class="card-body" style="background-color: #999999;">
                      <h4 class="card-text title-other">Fisherman’s Friend TVC</h4>
                      <p class="content-other"> Visual Effect</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="card-group">
                  <div class="card">
                    <img src="../assets/images/pertama/Asset 4300.png" class="card-img-top" alt="...">
                    <div class="card-body" style="background-color: #999999;">
                      <h4 class="card-text title-other">Riverview Residence</h4>
                      <p class="content-other">3D Archithecture</p>
                    </div>
                  </div>
                  <div class="card">
                    <img src="../assets/images/pertama/Asset 5300.png" class="card-img-top" alt="...">
                    <div class="card-body" style="background-color: #999999;">
                      <h4 class="card-text title-other">Kompas</h4>
                      <p class="content-other">Motion Graphic</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div> -->
    </div>
    </div>
</template>

<script>

export default {
    mounted () {
    window.scrollTo(0, 0)
  },
}
</script>


<style scoped>
.text-first{
  font-family: 'Avenir LT Std';
  color: #058CFF;
  font-weight: 800;
}

.title-list{
   font-family: 'Avenir LT Std';
   font-weight: 800;
   color: #333333;
}

.list-content{
  font-family: 'Futura Medium*';
  color: #999999;
}

.project-detail{
   padding-top: 80px;
}

.other{
  font-family: 'Avenir LT Std';
  font-weight: 800;
  color: #333333;
}
.title-other{
  font-family: 'Avenir LT Std';
  font-weight: 800;
  color: #333333;
}
.content-other{
  font-family: 'Avenir LT Std';
  font-weight: 400;
  color: #333333;
}

@media only screen and (max-width: 1000px) {
    .project-detail{
    padding-top: 75px;
    }
}
@media only screen and (max-width: 700px) {
    .project-detail{
    padding-top: 73px;
    }
}
</style>
